
import { useEffect, useState } from 'react'
import { NavBar } from '../../components/navbar';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { Quote } from '../../interface/quote.interface';

export const QuoteCreate = () => {
    const navigate = useNavigate();
    const [parameters, setParameters] = useState(Object);
    const [suggestedSlaDollar, setSuggestedSlaDollar] = useState(0);
    const [slaDollar, setSlaDollar] = useState(0);
    const [slaPeso, setSlaPeso] = useState(0);
    const [dailyRate, setDailyRate] = useState(0);
    const [hourlyRate, setHourlyRate] = useState(0);
    const quoteCollectionRef = collection(db, 'quotes');
    const parameterCollectionRef = collection(db, 'parameters');
    const [salary, setSalary] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [salaryXHours, setSalaryXHours] = useState(0);
    const [equipment, setEquipment] = useState(0);
    const [govDeductions, setGovDeductions] = useState(0);
    const [hmo, setHmo] = useState(0);
    const [commission, setCommission] = useState(0);
    const [opex, setOpex] = useState(0);
    const [monthlyTotal, setMonthlyTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [grossMargin, setGrossMargin] = useState(0);
    const [isFreelancer, setIsFreelancer] = useState(false);
    const [textColor, setTextColor] = useState('text-gray-500');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    
    useEffect(() => {
        const getParams = async () => {
            const data = await getDocs(parameterCollectionRef);
            const allParams = ((data.docs.map((doc) => ({...doc.data(), id: doc.id}))));
            setParameters(allParams[0]);
        }
        getParams();
    });

    /**
     * Computes monthly total and gross margin
     */
    useEffect(() => {
        const monthlyValue = salary + bonus + equipment + govDeductions + hmo + commission + opex;
        setMonthlyTotal(Number((monthlyValue).toFixed(2)));

        if (slaPeso && monthlyValue) {
            const grossMarginValue = ((slaPeso - monthlyValue)/ slaPeso);
            setGrossMargin(Number((grossMarginValue*100).toFixed(2)));
        }
    }, [salary, bonus, equipment, govDeductions, hmo, commission, opex]);

    /**
     * Computes for monthly total without commission
     */
    useEffect(() => {
        const monthlyValue = salary + bonus + equipment + govDeductions + hmo + opex;
        setSubTotal(monthlyValue);
    }, [salary, bonus, equipment, govDeductions, hmo, opex]);

    /**
     * Computes for suggested SLA Rate - USD
     */
    useEffect(() => {
        const baseMargin = 40;
        const suggestedSlaRate = computeSlaDollar(baseMargin);
        setSuggestedSlaDollar(suggestedSlaRate);
    }, [subTotal]);

    /**
     * Update fields affected by dollar change when SLA Rate - dollar changes
     */
    useEffect(() => {
        if (slaDollar > 0) {
            updateDollarChanges();
        } else {
            setSlaPeso(0);
            setCommission(0);
            setGrossMargin(0);
        }
    }, [slaDollar]);

    /**
     * Updates SLA Rate - Peso and Commission value
     */
    const updateDollarChanges = () => {
        const pesoValue = slaDollar * parameters.forex_rate;
        setSlaPeso(pesoValue);
        const commissionValue = pesoValue * (parameters.commission/100);
        setCommission(commissionValue);
    }

    /**
     * Computes for forex and adds value to SlaPeso and Commission
     * based on forex rate
     */
    const handleDollarChange = (event) => {
        setSlaDollar(event.target.value);
        updateDollarChanges();
    };

    const handleAskingChange = (event) => {
        const monthlyValue = Number(event.target.value)
        const daily = Number((monthlyValue/parameters.days).toFixed(2));
        setSalary(monthlyValue);
        const hourly = Number((daily / Number(parameters.hours)).toFixed(2));
        setDailyRate(daily);
        setHourlyRate(hourly);

        if (isFreelancer) {
            setSalaryXHours(monthlyValue);
            setBonus(0);
        } else {
            const salaryXHoursValue = hourly * parameters.salary_x_hours;
            setSalaryXHours(salaryXHoursValue);
            setBonus(salaryXHoursValue/12);
        }
    };

    /**
     * Computes for SLA - Dollar given the gross margin input
     */
    const computeSlaDollar = (gm: number): number => {
        // Check for division by zero (gm cannot be 0 or 1)
        if (gm === 0 || gm === 1) {
            throw new Error("gm cannot be 0 or 1");
        }
        return ((100 * subTotal) / ((100 - parameters.commission) - gm))/parameters.forex_rate;
    }

    /**
     * Computes for SLA Dollar value when gross Margin is defined
     */
    const handleGrossMarginChange = (event) => {
        const gm = Number(event.target.value);
        if (gm > 1) {
            const usdVal = computeSlaDollar(gm).toFixed(2);
            setSlaDollar(Number(usdVal));
        }
        setGrossMargin(gm);
    };

    const handleEquipmentChange = (event) => {
        setEquipment(Number(event.target.value));
    };

    const handleHmoChange = (event) => {
        setHmo(Number(event.target.value));
    };

    const handleGovDeductionsChange = (event) => {
        setGovDeductions(Number(event.target.value));
    };

    const handleOpexChange = (event) => {
        setOpex(Number(event.target.value));
    };

    /**
     * Checks if entered position has the word "freelance" to determine if
     * employee is a freelancer
     */
    const handlePositionChange = (event) => {
        const isFreelancerVal = (event.target.value).toLowerCase().includes('freelance');
        setIsFreelancer(isFreelancerVal);
        isFreelancerVal ? setBonus(0) : setBonus((salaryXHours/12));
    };

    /**
     * Gets all form values and submits form
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = e.target;
        const objData = {
            account_name: data.account.value,
            resource_name: data.name.value,
            resource_type: data.position.value,
            sla_usd: Number(data.slaUsd.value),
            sla_peso: slaPeso,
            commission: commission,
            salary: salary,
            daily_rate: dailyRate,
            hourly_rate: hourlyRate,
            salary_x_hours: salaryXHours,
            bonus: bonus,
            opex: opex,
            equipment: equipment,
            government_deductions: govDeductions,
            hmo: hmo,
            montly_total: monthlyTotal,
            gross_margin: grossMargin,
            param_exchange_rate: parameters.forex_rate,
            param_commission_rate: parameters.commission,
            param_days: parameters.days,
            param_hours: parameters.hours,
            param_salary_x_hours: parameters.salary_x_hours,
            archive: false,
            date_generated: Timestamp.now(),
        }
        createQuote(objData);
    }

    /**
     * Stores value of form in database
     * @param objData - input values in form
     */
    const createQuote = async (objData: Quote) => {
        try {
            await addDoc(quoteCollectionRef, objData);
            navigate('/');
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Stops increment/decrement of <input type="number"/> onScroll
     */
    const handleWheel = (event) => {
        event.target.blur();
    };

    /**
     * Changes text color of gross margin field
     * and enables/disables submit button
     */
    useEffect(() => {
        const colorValue = grossMargin > 39 ? 'text-green-600' : 'text-red-600'
        setTextColor(colorValue);
        slaDollar > 0 && salary > 0 ? setIsSubmitDisabled(false) : setIsSubmitDisabled(true);
    }, [grossMargin]);

    return (
        <div id='quote'>
            <NavBar/>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-[40px]">
                        <div className="sm:col-span-4">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Account Name</label>
                            <div className="mt-2 mb-5">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                    <input required type="text" name="account" className="block flex-1 pl-3 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>

                            <label className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                            <div className="mt-2 mb-5">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                    <input required type="text" name="name" className="block flex-1 pl-3 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>

                            <label className="block text-sm font-medium leading-6 text-gray-900">Position</label>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                <span className="inline-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1.5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>
                                Include "Freelance" or "Freelancer" if you don't want 13th month to be computed</span>
                            </p>
                            <div className="mt-2 mb-5">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                    <input type="text" name="position" onChange={handlePositionChange} className="block flex-1 pl-3 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-5 border-2"/>

                        <div className="mt-5 sm:col-span-4">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Asking Price</label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                    <input required type="number" name="asking" onChange={handleAskingChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Daily Rate <span className="mt-1 text-sm leading-6 text-gray-600">({parameters.days} days in a month)</span></label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="hourly" value={dailyRate} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Hourly Rate <span className="mt-1 text-sm leading-6 text-gray-600">({parameters.hours} hours a day)</span></label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="hourly" value={hourlyRate} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Salary x Hours <span className="mt-1 text-sm leading-6 text-gray-600">(Hourly x {parameters.salary_x_hours})</span></label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="salaryxhours" value={salaryXHours} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">13th Month Pay</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="bonusPay" value={bonus} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">OPEX</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input type="number" name="opex" onChange={handleOpexChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Equipment</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input type="number" name="equipment" onChange={handleEquipmentChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">SSS/PHIC/HMDF</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input type="number" name="gov" onChange={handleGovDeductionsChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">HMO</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input type="number" name="hmo" onChange={handleHmoChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-5 border-2"/>

                        <div className="mt-5 sm:col-span-4">
                            <label className="block text-sm font-medium leading-6 text-gray-900">SLA Rate - USD</label>
                            {suggestedSlaDollar ? <span className="inline-flex mt-1 text-sm leading-6 text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1.5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                                </svg>
                                Suggested value for a 40% Gross Margin: ${suggestedSlaDollar.toFixed(2)}</span> : ''}
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">$</span>
                                    <input required type="text" name="slaUsd" value={slaDollar} onChange={handleDollarChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">SLA Rate - PHP  <span className="mt-1 text-sm leading-6 text-gray-600">($1 = ₱ {parameters.forex_rate})</span></label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="slaPhp" value={slaPeso} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Commission <span className="mt-1 text-sm leading-6 text-gray-600">(Fixed at {parameters.commission}%)</span></label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="commission" value={commission} onWheel={handleWheel} className="block flex-1 pl-3 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-5 border-2"/>

                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Gross Margin</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <input required type="text" name="gross" value={grossMargin} onChange={handleGrossMarginChange} className={textColor + " block pl-3 flex-1 border-0 bg-transparent py-1.5 pl-1 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"}/>
                                        <span className="flex select-none items-center pr-3 text-gray-500 sm:text-sm">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Monthly Total</label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">₱</span>
                                        <input disabled type="text" name="monthly" value={monthlyTotal} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 mb-3">
                            <button type="submit" disabled={isSubmitDisabled} className="disabled:opacity-75 inline-flex default-button bg-black hover:bg-slate-800 text-white font-bold rounded">
                                Create Quote
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}