
import { useEffect, useState } from 'react'
import { NavBar } from '../components/navbar';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';

export const Parameter = () => {
    const navigate = useNavigate();
    const [parameters, setParameters] = useState(Object);
    const [isLoaded, setIsLoaded] = useState(false);
    const [id, setId] = useState('');
    const [forex, setForex] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);
    const [commission, setCommission] = useState(0);
    const [salaryXHours, setSalaryXHours] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const parameterCollectionRef = collection(db, 'parameters');

    /**
     * Stops increment/decrement of <input type="number"/> onScroll
     */
    const handleWheel = (event) => {
        event.target.blur();
    };

    useEffect(() => {
        const getParams = async () => {
            const data = await getDocs(parameterCollectionRef);
            const allParams = ((data.docs.map((doc) => ({...doc.data(), id: doc.id}))));
            setParameters(allParams[0]);
        }
        getParams();
    }, []);
    
    useEffect(() => {
        setSalaryXHours(parameters.salary_x_hours);
        setCommission(parameters.commission);
        setForex(parameters.forex_rate);
        setHours(parameters.hours);
        setDays(parameters.days);
        setId(parameters.id);
        if (parameters.forex_rate || parameters.hours) {
            setIsLoaded(true);
        }
    }, [parameters.id]);

    /**
     * Gets all form values and submits form
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsDisabled(true);
        editParam();
    }

    /**
     * Edits values of parameters
     * @param objData - input values in form
     */
    const editParam = async () => {
        try {
            const paramDoc = doc(db, 'parameters', id);
            const newFields = {
                forex_rate: Number(forex),
                commission: Number(commission),
                hours: Number(hours),
                days: Number(days),
                salary_x_hours: Number(salaryXHours)
            }
            await updateDoc(paramDoc, newFields)
            navigate('/');
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Handles input changes on forex field
     */
    const handleForexChange = (event) => {
        setForex(event.target.value);
    };

    /**
     * Handles input changes on hours field
     */
    const handleHoursChange = (event) => {
        setHours(event.target.value);
    };

    /**
     * Handles input changes on days field
     */
    const handleDaysChange = (event) => {
        setDays(event.target.value);
    };

    /**
     * Handles input changes on commission field
     */
    const handleCommissionChange = (event) => {
        setCommission(event.target.value);
    };

    /**
     * Handles input changes on salary x hours field
     */
    const handleSalaryXHoursChange = (event) => {
        setSalaryXHours(event.target.value);
    };

    return (
        <div id='quote'>
            <NavBar/>
            {isLoaded && (
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-[40px]">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="sm:col-span-4">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Forex Rate</label>
                                <div className="mt-2 mb-5">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">$1 = ₱</span>
                                        <input required type="number" name="forex" value={forex} onChange={handleForexChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Commission</label>
                                    <div className="mt-2 mb-5">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input type="number" name="commission" value={commission} onChange={handleCommissionChange} onWheel={handleWheel} className="block flex-1 pl-3 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                            <span className="flex select-none items-center pr-3 text-gray-500 sm:text-sm">%</span>
                                        </div>
                                    </div>
                                </div>

                                <label className="block text-sm font-medium leading-6 text-gray-900">Number of Hours per Day</label>
                                <div className="mt-2 mb-5">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input required type="number" name="hours" value={hours} onChange={handleHoursChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <label className="block text-sm font-medium leading-6 text-gray-900">Number of Days</label>
                                <div className="mt-2 mb-5">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input required type="number" name="hours" value={days} onChange={handleDaysChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <label className="block text-sm font-medium leading-6 text-gray-900">Total Number of Hours</label>
                                <div className="mt-2 mb-5">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input required type="number" name="hours" value={salaryXHours} onChange={handleSalaryXHoursChange} onWheel={handleWheel} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" disabled={isDisabled} className="inline-flex m-auto bg-black hover:bg-slate-800 text-white font-bold py-2 px-4 rounded">
                            Update Parameters
                        </button>
                    </form>
                </div>
            )}
        </div>
    )
}