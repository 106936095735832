
import { useEffect, useState } from 'react'
import { NavBar } from '../../components/navbar';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, where, query, orderBy } from 'firebase/firestore';
import { Quote } from '../../interface/quote.interface';

export const QuoteIndex = () => {
    const [quotes, setQuotes] = useState([]);
    const [displayedQuotes, setDisplayedQuotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [refreshData, setRefreshData] = useState(false);
    const [orderByValue, setOrderByValue] = useState('account_name');
    const [orderDirection, setOrderDirection] = useState<'desc' | 'asc'>('asc');
    const quoteCollectionRef = collection(db, 'quotes');

    /**
     * Get quotes from db
     */
    useEffect(() => {
        const getQuotes = async () => {
            const data = await getDocs(query(quoteCollectionRef, where('archive', '!=', true), orderBy(orderByValue, orderDirection)));
            setQuotes(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setDisplayedQuotes(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setRefreshData(false);
        }
        getQuotes();
    }, [orderByValue, orderDirection, refreshData]);

    /**
     * Duplicates selected quote
     * @param data - quote being duplicated
     */
    const duplicateData = (data) => {
        const confirmation = window.confirm(`Are you sure you want to duplicate ${data.account_name} quote?`);
        if (confirmation) {
            const objData = {...data, account_name: data.account_name + ' copy'}
            createQuote(objData);
        }
    }

    /**
     * Sets archive field to true
     * @param data - quote being deleted
     */
    const deleteData = async (data) => {
        const confirmation = window.confirm(`Are you sure you want to delete ${data.account_name} quote?`);
        if (confirmation) {
            try {
                const objData = {...data, archive: true}
                await updateDoc(doc(db, 'quotes', data.id), objData);
                setRefreshData(true);
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     * Stores value of copied quote in database
     * @param objData - input values in form
     */
    const createQuote = async (objData: Quote) => {
        try {
            await addDoc(quoteCollectionRef, objData);
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Filters table with values that match searched term
     * TODO: remove ID from being searched
     */
    const handleSearch = (event) => {
        event.preventDefault();
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        if (searchTerm) {
            const filteredItems = quotes.filter((item) => {
                return Object.entries(item).some(([key, value]) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setDisplayedQuotes(filteredItems);
        } else {
            setDisplayedQuotes(quotes);
        }
    };

    /**
     * Changes table sorting
     * @param order - value table is to be sorted by
     */
    const changeSort = (order: string) => {
        order === orderByValue ? setOrderDirection(orderDirection === 'desc' ? 'asc': 'desc') : setOrderDirection('asc');
        setOrderByValue(order);
    }

    return (
        <div id='quote'>
            <NavBar/>
            <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-[40px]'>
                <div className="pt-2 w-full inline-flex pb-[25px] relative mx-auto text-gray-600">
                    <input className="border-2 w-full border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                            type="search" name="search" placeholder="Search"value={searchTerm} onChange={handleSearch} />
                    <button type="submit" className="absolute right-0 top-0 mt-[14px] mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </button>
                </div>

                {/* TODO: fix mobile version of table */}
                <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                    <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                        <tr>
                            <th onClick={() => changeSort('account_name')} className='py-4 text-center cursor-pointer'>Company</th>
                            <th onClick={() => changeSort('resource_name')} className='py-4 text-center cursor-pointer'>Resource Name</th>
                            <th onClick={() => changeSort('resource_type')} className='py-4 text-center cursor-pointer'>Resource Type</th>
                            <th onClick={() => changeSort('gross_margin')} className='py-4 text-center cursor-pointer'>Gross Margin</th>
                            <th onClick={() => changeSort('date_generated')} className='py-4 text-center cursor-pointer'>Date Generated</th>
                            <th className='py-4 text-center'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedQuotes.map((quote) => {
                            return (
                                <tr key={quote.id}>
                                    <td className='py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white'>{quote.account_name}</td>
                                    <td className='py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white'>{quote.resource_name}</td>
                                    <td className='py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white'>{quote.resource_type}</td>
                                    <td className='py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white'>{quote.gross_margin} %</td>
                                    <td className='py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white'>{new Date(quote.date_generated.seconds * 1000).toLocaleDateString("en-US")}</td>
                                    <td className='flex justify-center gap-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                                        <a className='tooltip' href={'/view-quote/'+ quote.id}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                            </svg>
                                            <span className='tooltiptext'>View</span>
                                        </a>
                                        <a className='tooltip' href={'/edit-quote/'+ quote.id}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                            </svg>
                                            <span className='tooltiptext'>Edit</span>
                                        </a>
                                        <button className='tooltip' onClick={() => duplicateData(quote)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                            </svg>
                                            <span className='tooltiptext'>Copy</span>
                                        </button>
                                        <button className='tooltip' onClick={() => deleteData(quote)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                              <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                            <span className='tooltiptext'>Delete</span>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}