import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from './pages/login';
import { SignUp } from './pages/sign-up';
import { QuoteIndex } from './pages/quote/quote-index';
import { QuoteCreate } from './pages/quote/quote-create';
import { QuoteEdit } from './pages/quote/quote-edit';
import { QuoteView } from './pages/quote/quote-view';
import { Parameter } from './pages/parameters';
import { Error } from './pages/error';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase'
import { AuthRoute } from './components/auth-route';

import './assets/styles/main.scss';

initializeApp(firebaseConfig);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={
          <AuthRoute>
            <QuoteIndex/>
          </AuthRoute>
        }/>
        <Route path='create-quote' element={
          <AuthRoute>
            <QuoteCreate/>
          </AuthRoute>
        }/>
        <Route path='/edit-quote/:id' element={
          <AuthRoute>
            <QuoteEdit/>
          </AuthRoute>
        }/>
        <Route path='/view-quote/:id' element={
          <AuthRoute>
            <QuoteView/>
          </AuthRoute>
        }/>
        <Route path='parameters' element={
          <AuthRoute>
            <Parameter/>
          </AuthRoute>
        }/>
        <Route path='login' element={<Login/>}/>
        <Route path='sign-up' element={<SignUp/>}/>
        <Route path='*' element={<Error/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
