
import { Header } from '../components/header';

export const Error = () => {
    return (
        <div className="App">
            <Header/>
            this is the error page
        </div>
    )
}