import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthRoute = (props: any) => {
    const { children } = props;
    const auth = getAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoading(false);
            } else {
                console.log('Please login');
                navigate('/login');
            }
        })
    }, [auth]);

    if (loading) return <div>Loading...</div>;

    return (
        <>{children}</>
    )
}